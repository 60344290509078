<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="hetong">
    <van-nav-bar
      :title="$t('HT')"
      @click-left="$router.go(-1)"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <table class="gridtable">
      <tr>
          <th>ชื่อผู้กู้: </th>
          <th>{{name}}</th>
      </tr>
      <tr>
          <th>เลขที่สัญญา: </th>
          <th>{{id}}</th>
      </tr>
      <tr>
          <th>วัน/เดือน/ปี ที่กู้ยืม</th>
          <th>{{getZoneTimenum}}</th>
      </tr>
      <tr>
          <th>เลขประจำตัวประชาชน</th>
          <th>{{idCardNo}}</th>
      </tr>
      <tr>
          <th>เบอร์โทรศัพท์</th>
          <th>{{phone}}</th>
      </tr>
      <tr>
          <th>ยอดวงเงินกู้ (บาท )</th>
          <th>{{quota}}</th>
      </tr>
      <tr>
          <th>ระยะเวลาผ่อนชำระ(เดือน)</th>
          <th>{{months}}</th>
      </tr>
      <tr>
          <th>อัตราดอกเบี้ยต่อเดือน</th>
          <th>{{rate}}</th>
      </tr>
      <tr>
          <th>ยอดผ่อนชำระต่อเดือน(บาท)</th>
          <th>{{monthly}}</th>
      </tr>
      <tr>
          <th>กำหนดการชำระคืน</th>
          <th>วันที่ ของเดือน</th>
      </tr>
      <tr>
          <th>วีธีการกู้ยืม</th>
          <th class="more">
            <div>ผู้กู้จะต้องกรอกข้อมูลในระบบให้ครบถ้วน สมบูรณ์ ข้อมูลทั้งหมดจะต้องกรอกตามความเป็นจริง หากผู้กู้ให้ข้อมูลที่ไม่ตรงตามความเป็นจริง บริษัทสามารถเอาผิดผู้กู้ได้ตามกฎหมาย ข้อมูลทั้งหมดที่ได้รับการยืนยันจากผู้กู้แล้วไม่สามารถแก้ไขเปลี่ยนแปลงได้</div>
            <div>หลังจากที่มีการอนุมัติวงเงิน ผู้กู้จะต้องเริ่มชำระคืนเงินกู้ให้บริษัทในเดือนถัดจากเดือนที่มีการเบิกเงินกู้เป็นต้นไป โดยชำระเงินต้นและ
ดอกเบี้ยให้แก่บริษัทงวดละเท่าๆ กันทุกเดือน โดยผู้กู้จะต้องนำเงินเข้าบัญชีออมทรัพย์ที่แจ้งไว้กับบริษัททุกเดือน เป็นบัญชี
เดียวกันกับที่บริษัทโอนเงินกู้ให้ผู้กู้ และบริษัทจะทำการหักเงินในบัญชีเงินฝากตามที่ได้ตกลงกันจนกว่าจะชำระหนี้เสร็จสิ้น</div>
            <div>

เมื่อมีการลงนามในสัญญาดังกล่าว หากทางบริษัทพบว่าการกระทำที่ผิดปกติ ทางบริษัทมีสิทธิ์ในการระงับสินเชื่อของผู้กู้ชั่วคราว เพื่อดำเนินการตรวจสอบและหาแนวทางแก้ไขปัญหา ผู้กู้จะต้องชี้แจ้งให้ทราบถึงความผิดปกตินี้ให้ทางบริษัททราบ ภายใน 3 วัน มิฉะนั้นบริษัทมีสิทธิ์ที่จะปฏิเสธสัญญาฉบับนี้

ในการพิจารณาอนุมัติสินเชื่อทางบริษัทอาจใช้หลักทรัพย์ค้ำประกัน  ผลการพิจารณาสินเชื่อขึ้นอยู่กับทางคณะกรรมการผู้พิจารณาสินเชื่อตามข้อมูลของผู้กู้ที่ลงระบบและแจ้งต่อเจ้าหน้าที่สินเชื่อ

กรณีมีข้อผิดพลาดประการใดในการยื่นกู้ครั้งนี้โดยข้อผิดพลาดนั้นเกิดจากตัวผู้กู้  ผู้กู้ต้องแจ้งกับเจ้าหน้าที่เพื่อแก้ไขข้อผิดพลาดนี้ตามการพิจารณาของทางคณะกรรมการ หากผู้กู้ไม่ดำเนินการยื่นแก้ไขข้อผิดพลาดในส่วนนี้ที่เกิดขึ้น  ทางบริษัทสามารถยื่นฟ้องต่อศาลโดยตรงเพราะถือว่าเป็นการฉ้อโกงโดยเจตนาอย่างร้ายแรง และจะถูกลงโทษอย่างรุนแรงตามกฎหมาย สามารถทำการยึดหลักทรัพย์ของผู้กู้ได้ตามกฎหมาย  ( ค่าใช้จ่ายในการดำเนินการทั้งหมดในการดำเนินการตามขั้นตอนทางกฎหมายทางผู้กู้จะต้องเป็นผู้ออกทั้งหมด)</div>
          </th>
      </tr>
      <tr>
          <th>การเเนะนำ คุณสมบัติ</th>
          <th>บริษัท ของเราเป็น บริษัทเอกชน ที่ให้บริการทางการเงินและร่วมมือกับฝ่ายจัดหาเงิน และเมื่อวงเงินได้รับการอนุมัติ การชำระเงินจะเป็นทรัพย์สินส่วนตัวของผู้กู้ กล่าวคือเงินกู้มีผลทางกฎหมาย และต้องชำระเงินต้นและดอกเบี้ยที่สอดคล้องกันกับทางบริษัท
ก่อนการชำระคืนทางบริษัทจะมีการแจ้งเตือนผู้กู้ก่อนถึงกำหนดการชำระล่วงหน้า 3 วัน วิธีการชำระคืนคือหักจากบัญชีที่แจ้งไว้ในระบบ หรือชำระผ่านเคาน์เตอร์เซอร์วิส ไอโมบายแบงกิ้ง</th>
      </tr>
      <tr>
          <th>ความรับผิดชอบ
ในการผิดสัญญา
</th>
          <th>ผู้กู้ให้สัญญาว่าหากผู้กู้เกิดการยินยอมในข้อตกลงสัญญาแล้ว หากไม่ปฏิบัติตามสัญญาไม่ว่าข้อใดข้อหนึ่ง ทางบริษัทมีสิทธิ์บอกเลิกสัญญาและเรียกร้องให้ผู้กู้ชำระต้นพร้อมดอกเบี้ย คืนให้แก่บริษัททันที และผู้กู้ยินดีที่จะชำระค่าเสียหายต่างๆในการ เรียกทวงถาม ค่าทนาย ค่าธรรมเนียมต่างๆ ฯ ศาลเกี่ยวกับการฟ้องร้องด้วย                                                                                                                                                                                                       (1) ค่าใช้จ่ายอื่นๆ ทั้งหมดที่เกิดขึ้นตามข้อตกลงนี้
(2) ค่าธรรมเนียมการจัดการบัญชีที่ค้างชำระที่ระบุไว้
(3) ดอกเบี้ยค้างชำระที่ระบุไว้
(4) ค่าธรรมเนียมการจัดการบัญชีเ งินกู้
(5) ดอกเบี้ยเงินกู้ที่ระบุไว้
(6) ค่าธรรมเนียมการตรวจสอบจดหมายเร่งด่วน
(7) ค่าธรรมเนียมการจัดการบัญชีปกติ
(8) ดอกเบี้ยปกติ
(9) เงินต้นปกติ
          </th>
      </tr>
      <tr>
          <th>ลายมือชื่อผู้กู้</th>
          <th>
            <img width="200" :src="img" alt="">
          </th>
      </tr>
      <tr>
          <th>ลายเซ็นต์
ผู้ปล่อยสินเชื่อ</th>
          <th>
            <img width="200" :src="require('./img/hetong.png')" alt="">
          </th>
      </tr>
      <!-- <tr>
          <th>ลายเซ็นบริษัทเงินกู้</th>
          <th>
            <img width="200" :src="require('./img/signimg.png')">
          </th>
      </tr> -->
      </table>
  </div>
</template>

<script>
import './css/my.css'
import { getAutograph, getLoanAgreementInfo } from '@/api/my'
import { loanInfo } from '../../api/wallet'
export default {
  name: 'hetong',
  data () {
    return {
      // 贷款编号
      id: '',
      // 签名
      img: '',
      // 手机号
      phone: '',
      // 贷款金额
      quota: '',
      // 利率
      rate: '',
      // 姓名
      name: '',
      // 贷款期限
      months: '',
      // 身份证
      idCardNo: '',
      // 当前时间
      getZoneTimenum: '',
      // 每月分期付款金額
      monthly: ''
    }
  },
  created () {
    // this.getSign()
    this.init_getLoanAgreementInfo()
    this.getLoanId()
  },
  methods: {
    // 获取贷款合同编号
    async getLoanId () {
      const data = await loanInfo()
      this.id = data.data.id
    },
    // 获取签名
    async getSign () {
      const { data } = await getAutograph()
      this.img = `${data.data}`
    },
    // 获取签名
    async init_getLoanAgreementInfo () {
      const { data } = await getLoanAgreementInfo()
      this.id = data.data.id
      this.img = `${data.data.autograph}`
      this.idCardNo = data.data.idCardNo
      this.months = data.data.months
      this.name = data.data.name
      this.phone = data.data.phone
      this.quota = data.data.quota
      this.rate = data.data.rate
      this.monthly = data.data.monthly
      this.getZoneTimenum = this.getLocalTime(8)
    },
    // 获取时间
    getLocalTime (i) {
      // 参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
      if (typeof i !== 'number') return
      var d = new Date()
      // 得到1970年一月一日到现在的秒数
      var len = d.getTime()
      // 本地时间与GMT时间的时间偏移差
      var offset = d.getTimezoneOffset() * 60000
      // 得到现在的格林尼治时间
      var utcTime = len + offset
      var time = new Date(utcTime + 3600000 * i)
      // return time.getFullYear() + '-' + time.getMonth() + 1 +'-'+dd+' '+hh+':'+mm+':'+ss
      return `${time.getFullYear()}-${time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1}-${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()}`
    }
  }
}
</script>

<style  scoped>
table.gridtable {
  width: 100%;
  font-family: verdana,arial,sans-serif;
  font-size: 15Px;
  color:#333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
}
table.gridtable th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #fff;
}
table.gridtable th:nth-of-type(1) {
  background-color: #dedede;
}
table.gridtable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
}
.more {
  padding: 0 !important;
}
.more div {
  border-bottom: 1Px solid #000;
  padding: 5px;
}
</style>
